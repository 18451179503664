
/* @import '../mixins/ckedtor-styles.scss'; */

/* .my-editor__container {
  @include ckeditorStyles();
  p {
    margin-top:0.2em;
    margin-bottom: 0.8em;
  }
  ul, ol {
    margin-left: 2.666em;
    margin-bottom: 0.8em;

  }
  ul {
    list-style:disc;
    ul {
      list-style:circle;
      ul {
        list-style: square;
      }
    }
  }
  ol {
    list-style: decimal;
    ol {
      list-style: lower-latin;
      ol {
        list-style: lower-roman;
        ol {
          list-style: upper-latin;
          ol {
            list-style: upper-roman;
          }
        }
      }
    }
  }
  a {
    color: rgb(85, 85, 255);
  }
  h1, h2, h3 {
    margin-top:0.8em;
    margin-bottom: 0.2em;
  }
  .ck-content  {
    min-height:150px;
  }
} */

.my-editor__container p {
  margin-top:0.2em;
  margin-bottom: 0.8em;
  font-size: 16px;
}

.my-editor__container ul,
.my-editor__container ol {
  margin-left: 2.666em;
  margin-bottom: 0.8em;

}

.my-editor__container > ul {
  list-style:disc;
}
.my-editor__container > ul > ul {
  list-style:circle;
}
.my-editor__container > ul > ul > ul {
  list-style: square;
}

.my-editor__container > ol {
  list-style: decimal;
}
.my-editor__container > ol > ol {
  list-style: lower-latin;
}
.my-editor__container > ol > ol > ol {
  list-style: lower-roman;
}
.my-editor__container > ol > ol > ol > ol {
  list-style: upper-latin;
}
.my-editor__container > ol > ol > ol > ol > ol {
  list-style: upper-roman;
}

.my-editor__container a {
  color: rgb(85, 85, 255);
  cursor: pointer;
}
.my-editor__container h1,
.my-editor__container h2,
.my-editor__container h3,
.my-editor__container h4 {
  margin-top:0.8em;
  margin-bottom: 0.2em;
}
.my-editor__container h2 {
  font-size: 24px;
  font-weight: 700;
}
.my-editor__container h3 {
  font-size: 19px;
  font-weight: 700;

}
.my-editor__container h4 {
  font-size: 16px;
  font-weight: 700;

}
.my-editor__container .ck-content  {
  min-height:150px;
}